import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ROPEZE_BANNER_1 from '../assets/ROPEZE_BANNER_1.jpg'
import ROPEZE_BANNER_2 from '../assets/ROPEZE_BANNER_2.jpg'
import ROPEZE_BANNER_3 from '../assets/ROPEZE_BANNER_3.jpg' 
import ROPEZE_BANNER_4 from '../assets/Carousel-clamps.png' 
import Backtotop from './Backtotop'
import About from './About';
import Contact from './Contact';
import Wheretobuy from './Wheretobuy';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Helmet } from 'react-helmet'

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  // top: 'calc(50% - 15px)',
  top: "5%",
  width: "3%",
  height: "100%",
  color: "#0000FF",
  cursor: "pointer",
  "&hover": {
    top: 0,
    bottom: 0,
    backgroundColor: "lightgrey",
    opacity: ".4",
  },
};



function Home () {     
  return (
    <div>  
        <Helmet>
           <title>Ropeze</title>
         </Helmet>          
     <Backtotop/>                                              
     <Carousel 
      autoPlay 
      transitionTime={1000} 
      interval={8000} 
      infiniteLoop 
      showThumbs={false}  
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <ArrowBackIosIcon
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyles, left: 15 }}
          />
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <ArrowForwardIosIcon
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyles, right: 15 }}
          />
        )
      }>
      <div><img src={ROPEZE_BANNER_2} alt="banner2" title="banner2" /></div>
      <div><img src={ROPEZE_BANNER_4} alt="banner4" title="banner4"/></div>
      <div><img src={ROPEZE_BANNER_3} alt="banner3" title="banner3"/></div> 
      <div><img src={ROPEZE_BANNER_1} alt="banner1" title="banner1"/></div> 
      </Carousel>   
      <Wheretobuy/>              
      <Contact/> 
      <About/>  
   </div>
    );
   }
 export default Home;
