import React from 'react'
import { Paper, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import clamp from '../assets/clamps.JPG'
import clamp1 from '../assets/clamps1.JPG'

const DescriptionComponent = ({ product, p1, p2, p3, p4, p5, subtitle}) => {
  const classes = styles();
  return (
    <>
    <div className={classes.descriptionDiv}>
        <Typography
          align="left"
          className={classes.pagesubtitle}
          style={{ fontFamily: "Lato",padding: "30px 0px", color: "white", marginBottom: 0 }}
        >
          {product} Description
        </Typography>
        </div>
        <Paper style={{padding: "17px", margin: "0 10px"}}>
        <Typography
          className={classes.pagesubtitle}
          style={{ textAlign: "left",fontWeight: "bold", marginTop: "0" }}
        >
          <strong>{subtitle}</strong>
        </Typography>
        <Typography className={classes.pagecontent} style={{marginTop: 0}}>
          {p1}
        </Typography>
        <Typography className={classes.pagecontent}>
          {p2}
        </Typography>
        <Typography className={classes.pagecontent}>
          {p3}
        </Typography>
        <Typography className={classes.pagecontent} style={{textAlign: "center"}}>
        <Grid container spacing={4} justify="center" alignItems="center">
          <Grid item xs={10} md={5} sm={4}>
          <div title="Ropeze Rope Clamp">
          <ModalImage
              small={clamp}
              large={clamp}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Ropeze Rope Clamp"
            />
            </div>
      
            </Grid>
            <Grid item xs={10} md={5} sm={4}>
              <div title="Ropeze Rope Clamp Closeup">
            <ModalImage
              small={clamp1}
              large={clamp1}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Ropeze Rope Clamp Closeup"
            />
            </div>
                </Grid>
            </Grid>
            
        </Typography>
        <Typography className={classes.pagecontent}>
          {p5}
        </Typography>
        </Paper>
    </>
  )
}

export default DescriptionComponent
