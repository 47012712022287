import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/AboutStyles";
import aboutImg from "./../assets/ROPEZE_ABOUT2.png";

export default function About() {
  const classes = styles();
  return (
    <div id="about" className={classes.aboutDiv}>
      <Container
        maxWidth="lg"
        style={{
          flexGrow: "1",
        }}
      >
        <Typography align="center" className={classes.pagetitle}>
          About Us
        </Typography>

        <div style={{ flexBasis: "none" }}>
          <Grid container>
            <Grid
              item
              lg={5}
              xs={12}
              md={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={aboutImg} className={classes.aboutimg} alt="aboutimg" title="aboutimg" />
            </Grid>

            <Grid item lg={7} xs={12} md={8} style={{ padding: "0%" }}>
              <Typography className={classes.pagecontent}>
                We created our original Ropeze products to answer our customers’
                need for reliable, easy-to-use rope rigging solutions. We
                applied our engineering expertise to current designs, improving
                materials, form, and function to produce the most reliable rope
                accessories available.
              </Typography>
              <Typography className={classes.pagecontent}>
                Before launching, we test and retest our designs until we are
                satisfied they can meet any rigging need. Our goal is to provide
                high-quality rope accessories with the reliability you expect
                and at the prices you want.
              </Typography>
              <Typography className={classes.pagecontent}>
                Ropeze rope accessories and cleats are products of Sea‐Land
                Distributors, LLC.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
