import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import foldedCleat from "../assets/Ropeze-SS-Folding-Cleat-closed.jpg";
import unfoldedCleat from "../assets/Ropeze-SS-Folding-Cleat.jpg";
import WhereToBuy2 from "./WhereToBuy2";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DescriptionComponent from "./DescriptionComponent2";
import { Helmet } from 'react-helmet'


export default function () {
  const classes = styles();
  return (
    <div>
      <Helmet>
        <title>Folding Dock Cleats</title>
        </Helmet>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography align="center" className={classes.pagetitle} gutterBottom>
          Folding Dock Cleats
        </Typography>
     {//test1
     }
        <Grid container spacing={4} justify="center" alignItems="center">
          <Grid item xs={10} md={6} sm={4}>
          <div title="folded cleat">
            <ModalImage
              small={foldedCleat}
              large={foldedCleat}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="folded cleat"
              aria-labelledby="folded cleat"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="folded cleat">
            <ModalImage
              small={unfoldedCleat}
              large={unfoldedCleat}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="folded cleat"
              aria-labelledby="folded cleat"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Dock Cleats"
          subtitle="Ropeze stainless steel folding cleats are engineered for strength and
          durability."
          p1="Solid 316 stainless-steel construction makes this
          flip-down cleat stronger than other steel deck and dock cleats."
          p2=" We designed this cleat with a low profile and rounded edges for added
          safety against trips. Every component is precision manufactured with
          smooth curves and no hard corners so you can tie up securely with no
          unnecessary wear on ropes and other equipment."
          p3='Sized for 3/8" Bolt or Carriage Bolt.'
        />
    
      </Container>
      <Container maxWidth="md" component="main">
        <Typography
          align="center"
          className={classes.pagesubtitle}
          style={{ fontSize: "30px" }}
          gutterBottom
        >
          Stainless Steel Folding Cleat
        </Typography>
       
        <TableContainer component={Paper} style={{ marginBottom: "25px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0000FF" }}>
              <TableRow>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Part Number
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Material
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Weight (lbs)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Minimum Breaking Load (MBL)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>51640401</TableCell>
                <TableCell className={classes.tcell}>
                  Type 316 Stainless Steel
                </TableCell>
                <TableCell className={classes.tcell}>2.24 </TableCell>
                <TableCell className={classes.tcell}>10,000+</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <WhereToBuy2 />
      </Container>
    </div>
  );
}
