import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import WhereToBuy2 from "./WhereToBuy2";
import RopeClamp from "../assets/Rope-Clamp.jpg";
import RopeClampFront from "../assets/clamp.png";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DescriptionComponent from "./DescriptionComponent3";
import { Helmet } from 'react-helmet'


export default function () {
  const classes = styles();
  return (
    <div>
      <Helmet>
        <title>Rope Clamps</title>
      </Helmet>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography
          align="center"
          className={classes.pagetitle}
          gutterBottom
        >
          Ropeze Rope Clamps
        </Typography>
     
        <Grid container spacing={4} justify="center" alignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div title="Rope Clamp">
            <ModalImage
              small={RopeClamp}
              large={RopeClamp}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Rope Clamp"
              aria-labelledby="Rope Clamp"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="Rope Clamp">
            <ModalImage
              small={RopeClampFront}
              large={RopeClampFront}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Rope Clamp"
              aria-labelledby="Rope Clamp"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Rope Clamps"
          subtitle="   Ropeze rope clamps are engineered for simplicity, needing only a
          screwdriver to easily create strong rope loops and eyes without
          splicing or knots"
          p1="Ropeze rope clamps are engineered for simplicity, needing only a
          screwdriver to easily create strong rope loops and eyes without
          splicing or knots"
          p2="   Our booster clamps double the working load limit of your setup for
          even more security. All Ropeze products are thoroughly load-tested to
          ensure dependability."
          p3=" Ropeze rope fasteners are rock-solid, with robust gripping teeth,
          stainless screws, and durable brass nuts for maximum holding power.
          UV-resistant ABS plastic construction means our clamps are reusable
          without sacrificing reliability."
        />

  
        
        <Typography style={{marginBottom: "25px"}} className={classes.pagecontent} component={Paper}>
          <div className={classes.listHeader}>
          <strong className={classes.listTitle}>Ropeze Rope Clamp Features</strong>
          </div>
          <ul className={classes.ulSpacing}>
            <li className={classes.listItems}>
              Made from high-strength UV-resistant ABS plastic
            </li>
            <li className={classes.listItems}>
              Fastening hardware includes 316 Stainless steel screws and brass
              embedded nuts
            </li>
            <li className={classes.listItems}>
              Corrosion-resistant parts for marine environments
            </li>
            <li className={classes.listItems}>
              Ropeze clamps easily form rope eye loops in seconds for a variety
              of applications
            </li>
            <li className={classes.listItems}>No splicing or knots required</li>
            <li className={classes.listItems}>
              Rope clamps are durable and reusable
            </li>
            <li className={classes.listItems}>
              Booster clamps can double the working load limit of your setup
            </li>
          </ul>
          </Typography>
          <Typography style={{marginBottom: "25px"}} className={classes.pagecontent} component={Paper}>
            <div className={classes.listHeader}>
              <strong className={classes.listTitle}>Ropeze Rope Clamp Instructions</strong>
            </div>

          <ol className={classes.ulSpacing} style={{ marginTop: "10px" }}>
            <li className={classes.listNumbers}>
              Put screws in unthreaded half, and place on flat surface with
              screws facing up.
            </li>
            <li className={classes.listNumbers}>
              Lay rope in clamp to form the desired eye length.
            </li>
            <li className={classes.listNumbers}>
              Place the clamp half with threaded inserts on top and lightly
              press the pieces together
            </li>
            <li className={classes.listNumbers}>
              While holding the assembly in place, turn the assembly and tighten
              the screws evenly
            </li>
          </ol>
          </Typography>
          <Typography style={{marginBottom: "25px"}} className={classes.pagecontent} component={Paper}>
            <div className={classes.listHeader}>
              <strong className={classes.listTitle}>Notes For Use</strong>
            </div>
           
          <ul className={classes.ulSpacing}>
            <li className={classes.listItems}>
              Do not exceed the load limits recommended by your rope’s
              manufacturer
            </li>
            <li className={classes.listItems}>
              Ropeze Rope Clamps and Booster Clamps are designed for solid-braid
              nylon and polypropylene ropes, but they work very well with other
              rope types
            </li>
            <li className={classes.listItems}>
              Ropeze Rope Clamps make it easy for anyone to safely add an eye
              loop to rope
            </li>
            <li className={classes.listItems}>
              Ropeze Rope Clamps are designed to be used with fiber rope only.
              They are not to be used with wire rope or cable.
            </li>
            <li className={classes.listItems}>
              Ropeze Clamps are sized for solid-braid rope. Use the next size up
              for stiff or dense rope, triple-strand rope, kernmantle or
              double-braid rope, manila rope, or hemp rope
            </li>
          </ul>
          </Typography>

          <Typography
          align="center"
          className={classes.pagesubtitle}
          style={{marginBottom: 0, paddingTop: 0, fontSize: "30px"}}
          gutterBottom
        >
          Fit Size and Guidelines
        </Typography>
        <Typography
          align="right"
          className={classes.pagesubtitle}
          style={{ fontSize: "15px", marginBottom: "5px", marginTop: 0 }}
          gutterBottom
        >
          Dimensions are in inches unless otherwise noted.
        </Typography>
          <TableContainer component={Paper} style={{ marginBottom: "25px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0000FF" }}>
              <TableRow>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Clamp Size
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Rope/Cordage
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Works Well With
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>1/4" Ropeze Clamps</TableCell>
                <TableCell className={classes.tcell}>
                  1/4" or 5/16" 
                </TableCell>
                <TableCell className={classes.tcell}>8mm Rope </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>3/8" Ropeze Clamps/Booster Clamps</TableCell>
                <TableCell className={classes.tcell}>
                  3/8"
                </TableCell>
                <TableCell className={classes.tcell}>9mm Rope </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>1/2" Ropeze Clamps/Booster Clamps</TableCell>
                <TableCell className={classes.tcell}>
                  7/16" or 1/2" 
                </TableCell>
                <TableCell className={classes.tcell}>12 & 13mm Rope </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>5/8" Ropeze Clamps/Booster Clamps</TableCell>
                <TableCell className={classes.tcell}>
                  9/16" or 5/8" 
                </TableCell>
                <TableCell className={classes.tcell}>15 & 16mm Rope </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>3/4" Ropeze Clamps/Booster Clamps</TableCell>
                <TableCell className={classes.tcell}>
                  3/4" 
                </TableCell>
                <TableCell className={classes.tcell}>19mm Rope </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
         
 
      </Container>
      <Container maxWidth="lg" component="main">
        <Typography
          align="center"
          className={classes.pagesubtitle}
          style={{marginBottom: 0, paddingTop: 0, fontSize: "30px"}}
          gutterBottom
        >
          Rope Clamp Only
        </Typography>
        <Typography
          align="right"
          className={classes.pagesubtitle}
          style={{ fontSize: "15px", marginBottom: "5px", marginTop: 0 }}
          gutterBottom
        >
          Dimensions are in inches unless otherwise noted.
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0000FF" }}>
              <TableRow>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Part Number
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Ropeze Size
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Hole Width (D1)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Hole Height (D2)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Height (T)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Length Screw to Screw (B)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Length (L)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Width (W)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Rope Exit Height (E1)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Rope Exit Width (E2)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Working Load Limit (5:1) (lbs)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Breaking Load (lbs)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91412205</TableCell>
                <TableCell className={classes.tcell}>1/4"</TableCell>
                <TableCell className={classes.tcell}>0.3</TableCell>
                <TableCell className={classes.tcell}>0.3</TableCell>
                <TableCell className={classes.tcell}>0.55</TableCell>
                <TableCell className={classes.tcell}>0.7</TableCell>
                <TableCell className={classes.tcell}>1.7</TableCell>
                <TableCell className={classes.tcell}>0.86</TableCell>
                <TableCell className={classes.tcell}>0.35</TableCell>
                <TableCell className={classes.tcell}>0.3</TableCell>
                <TableCell className={classes.tcell}>40</TableCell>
                <TableCell className={classes.tcell}>200</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91412305</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>0.385</TableCell>
                <TableCell className={classes.tcell}>0.45</TableCell>
                <TableCell className={classes.tcell}>0.775</TableCell>
                <TableCell className={classes.tcell}>0.8</TableCell>
                <TableCell className={classes.tcell}>1.96</TableCell>
                <TableCell className={classes.tcell}>1</TableCell>
                <TableCell className={classes.tcell}>0.47</TableCell>
                <TableCell className={classes.tcell}>0.3825</TableCell>
                <TableCell className={classes.tcell}>45</TableCell>
                <TableCell className={classes.tcell}>225</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91412405</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>0.5</TableCell>
                <TableCell className={classes.tcell}>0.5</TableCell>
                <TableCell className={classes.tcell}>0.85</TableCell>
                <TableCell className={classes.tcell}>1</TableCell>
                <TableCell className={classes.tcell}>2.45</TableCell>
                <TableCell className={classes.tcell}>1.27</TableCell>
                <TableCell className={classes.tcell}>0.53</TableCell>
                <TableCell className={classes.tcell}>0.5</TableCell>
                <TableCell className={classes.tcell}>115</TableCell>
                <TableCell className={classes.tcell}>575</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91412455</TableCell>
                <TableCell className={classes.tcell}>5/8"</TableCell>
                <TableCell className={classes.tcell}>0.615</TableCell>
                <TableCell className={classes.tcell}>0.625</TableCell>
                <TableCell className={classes.tcell}>0.915</TableCell>
                <TableCell className={classes.tcell}>1.4</TableCell>
                <TableCell className={classes.tcell}>2.98</TableCell>
                <TableCell className={classes.tcell}>1.55</TableCell>
                <TableCell className={classes.tcell}>0.66</TableCell>
                <TableCell className={classes.tcell}>0.57</TableCell>
                <TableCell className={classes.tcell}>162</TableCell>
                <TableCell className={classes.tcell}>810</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91412505</TableCell>
                <TableCell className={classes.tcell}>3/4"</TableCell>
                <TableCell className={classes.tcell}>0.73</TableCell>
                <TableCell className={classes.tcell}>0.76</TableCell>
                <TableCell className={classes.tcell}>1.13</TableCell>
                <TableCell className={classes.tcell}>1.5</TableCell>
                <TableCell className={classes.tcell}>3.85</TableCell>
                <TableCell className={classes.tcell}>1.96</TableCell>
                <TableCell className={classes.tcell}>0.8</TableCell>
                <TableCell className={classes.tcell}>0.74</TableCell>
                <TableCell className={classes.tcell}>195</TableCell>
                <TableCell className={classes.tcell}>975</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography
          align="center"
          className={classes.pagesubtitle}
          style={{marginBottom: 0, paddingTop: "30px", fontSize: "30px"}}

        >
          Rope Clamp With Booster Clamp
        </Typography>
        <Typography
          align="right"

          className={classes.pagesubtitle}
          style={{ fontSize: "15px", marginBottom: "5px", marginTop: 0 }}

          gutterBottom
        >
          Dimensions are in inches unless otherwise noted.
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "45px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0000FF" }}>
              <TableRow>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Part Number
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Ropeze Size
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Hole Width (D1)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Hole Height (D2)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Height (T)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Length Screw to Screw (B)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Length (L)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Width (W)
                </TableCell>

                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Working Load Limit (5:1) (lbs)
                </TableCell>
                <TableCell
                  className={classes.theader}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontFamily: "Lato",
                    fontSize: "18px",
                  }}
                >
                  Breaking Load (lbs)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91413302</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>0.375</TableCell>
                <TableCell className={classes.tcell}>0.375</TableCell>
                <TableCell className={classes.tcell}>0.68</TableCell>
                <TableCell className={classes.tcell}>0.8</TableCell>
                <TableCell className={classes.tcell}>1.57</TableCell>
                <TableCell className={classes.tcell}>1.08</TableCell>
                <TableCell className={classes.tcell}>90</TableCell>
                <TableCell className={classes.tcell}>450</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91413402</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>0.5</TableCell>
                <TableCell className={classes.tcell}>0.52</TableCell>
                <TableCell className={classes.tcell}>0.83</TableCell>
                <TableCell className={classes.tcell}>1</TableCell>
                <TableCell className={classes.tcell}>1.95</TableCell>
                <TableCell className={classes.tcell}>1.28</TableCell>
                <TableCell className={classes.tcell}>230</TableCell>
                <TableCell className={classes.tcell}>1150</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91413452</TableCell>
                <TableCell className={classes.tcell}>5/8"</TableCell>
                <TableCell className={classes.tcell}>0.63</TableCell>
                <TableCell className={classes.tcell}>0.59</TableCell>
                <TableCell className={classes.tcell}>0.89</TableCell>
                <TableCell className={classes.tcell}>1.4</TableCell>
                <TableCell className={classes.tcell}>2.4</TableCell>
                <TableCell className={classes.tcell}>1.57</TableCell>
                <TableCell className={classes.tcell}>324</TableCell>
                <TableCell className={classes.tcell}>1620</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91413502</TableCell>
                <TableCell className={classes.tcell}>3/4"</TableCell>
                <TableCell className={classes.tcell}>0.75</TableCell>
                <TableCell className={classes.tcell}>0.8</TableCell>
                <TableCell className={classes.tcell}>1.15</TableCell>
                <TableCell className={classes.tcell}>1.7</TableCell>
                <TableCell className={classes.tcell}>3.2</TableCell>
                <TableCell className={classes.tcell}>1.9</TableCell>
                <TableCell className={classes.tcell}>390</TableCell>
                <TableCell className={classes.tcell}>1950</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <WhereToBuy2 />
      </Container>
    </div>
  );
}
