import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  main: {
    paddingBottom: "20px",
    minHeight: "50vh"
  },
  pagetitle:{
      color: '#231f20',
      fontFamily:'Lato',
      paddingTop:'30px',
      fontSize: '42px',
      [theme.breakpoints.down("sm")]: {
        fontSize: '30px',
     }
    },
    pagesubtitle:{
     fontFamily: 'Lato',
     margin: '15px',
     fontSize: '22px',
     fontWeight: "bold",
     [theme.breakpoints.down("sm")]: {
      fontSize: '19px',
   }
 },
 pagecontent: {
     fontFamily: 'Lato',
     margin: '10px',
     fontSize: '18px'
 },
 img: {
     height: '250px', 
     padding:'10px',
     marginBottom:'20px',
     border: '3px solid #0000FF'    
 },
 link: {   
     fontFamily: 'Lato',
     margin: '25px',
     fontSize: '32px',
     float: 'right',
     '&:hover': {
         transform:'scale(1.1)',
         transition: 'transform .3s ease',
        }
 },
 card: {
     padding: '5px',
 },
 theader: {
     fontWeight: 'bold',
     fontFamily: 'Lato',
     fontSize: '18px',
     color: 'white'
    },
    tcell:{
      fontFamily: 'Lato' 
    },
  shopButtonDiv: {
    backgroundColor: '#1C2849'
  },
  shopButtonLink: {
   color: 'white'
  },
  shopButton: {
    margin: "0 auto", fontFamily: "Lato"
  },
  listItems: {
    display: "list-item", 
    listStyleType: "circle",
    marginBottom: "4px" 
  },
  listNumbers: {
    marginBottom: "4px" 
  },
  listHeader: {
    backgroundColor: "#000FFF", 
    padding: "15px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px"
  },
  listTitle: {
    color: "white",
    fontSize: "20px"
  },
  ulSpacing: {
    paddingLeft: "40px",
    marginTop: "10px",
    marginBottom: "10px",
    paddingBottom: "10px"
  },
  descriptionDiv: {
    backgroundColor: "#0000FF",
    paddingLeft: "20px",
    marginBottom: 0,
    marginLeft: "10px",
    marginRight: "10px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px"
  }
  

  }));